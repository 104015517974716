.photos {
    background-color: white;
}

.folders-container {
    padding-top: 64px;
    width: 100%;
    height: 20vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.folder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
}

.folder {
    width: 65%;
    padding: 5%;
}

.folder-name {
    font-size: 1.5vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-transform: uppercase;
    color: black;
}