.main-photos {
    background-color: black;
}

.full-section {
    padding-top: 64px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.half-section-container {
    padding-top: 64px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.half-section-left {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.half-section-right {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 3%;
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.allkpop-full-photo {
    width: 60%;
    box-shadow: 0 0 50px white; 
    margin: 2%;
}

.allkpop-half-photo {
    width: 80%;
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.7);  
    margin: 2%;
}

.empty-placeholder {
    height: 500px;
}