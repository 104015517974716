.main {
    background-color: white;
}

.section {
    padding-top: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.upper-title {
    font-size: 1.5vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 3%;
    padding-left: 3%;
}

.preview {
    width: 70%;
    font-size: 7vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 3%;
    padding-left: 3%;
}

.bottom-details {
    font-size: 1.1vw;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 1%;
    padding-left: 3%;
    padding-bottom: 6%;
}

.main-slide {
    width: 100%;
    padding-bottom: 3%;
}

.slides-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.slide {
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;
}

.indiv-description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
}

.indiv-description {
    width: 60%;
    font-size: 2vw;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 10%;
    padding-bottom: 3%;
}

.bulleted-list {
    font-size: 1.2vw;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: left;
    color: black;
    margin: 0;
    padding-bottom: 3%;
}

.artist-names {
    font-size: 3vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: justify;
    color: black;
    margin: 0;
}

.coming-soon {
    font-size: 4vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: center;
    color: black;
    margin: 0;
    align-self: center;
    margin-top: 40px;
}

.external-link-title {
    font-size: 1.4vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: right;
    color: black;
    margin: 0;
    padding-bottom: 3%;
    transition: 0.5s linear;
}

.external-link-title:hover {
    color: #eb3b5d;
}