.menu {
    background: linear-gradient(90deg, black 50%, white 50%);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.left-container {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.right-container {
    width: 50%;
    height: 100vh;
    display: flex;
    padding-left: 5vw;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.menu-text {
    font-size: 10vmin;
    font-family: 'Pretendard';
    font-weight: 600;
    line-height: 0;
    color: white;
    transition: 0.5s linear;
    padding-left: 5vw;
}

.menu-text:hover {
    color: #eb3b5d;
}

.social-text {
    font-size: 6vmin;
    font-family: 'Pretendard';
    font-weight: 600;
    line-height: 0;
    color: black;
    transition: 0.5s linear;
}

.social-text:hover {
    color: #eb3b5d;
}