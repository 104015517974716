.portfolio {
    background-color: white;
}

.section-portfolio {
    padding-top: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 3%;
}

.portfolio-title {
    font-size: 10vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 3%;
    padding-left: 4%;
    width: 100%;
    max-height: 14vw;
}

.title-link {
    text-decoration: none;
}

.small-title {
    font-size: 1.5vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 10%;
    padding-left: 3%;
}

.description {
    font-size: 3.5vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    color: black;
    margin: 0;
    padding-top: 10%;
    padding-left: 3%;
    padding-bottom: 12%;
}

.arrow {
    max-width: 7vw;
    height: auto;
}

.main-photo {
    max-width: 95%;
    height: auto;
    align-self: center;
}

.description-container {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.empty-container {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.horizontal-line {
    width: 95%;
    border: 1px solid black;
}

