.archive {
    background-color: white;
}

.section-archive {
    padding-top: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 4%;
    padding-bottom: 3%;
}

.title-archive {
    font-size: 7vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    color: black;
    margin: 0;
    width: 100%;
    max-width: 90vw;
    transition: 0.5s linear;
}

.title-archive:hover {
    color: #eb3b5d;
}