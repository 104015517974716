.home {
    background-color: transparent;
}

.inflatable-letter-h {
    padding-top: 2vmin;
    width: 5vmin;
}

.inflatable-letter-l {
    padding-top: 2vmin;
    width: 3.9vmin;
}

.inflatable-letter-m {
    padding-top: 2vmin;
    width: 5.8vmin;
}

.inflatable-letter-e {
    padding-top: 2vmin;
    width: 3.8vmin;
}

.inflatable-letter-n {
    padding-top: 2vmin;
    width: 4.9vmin;
}

.inflatable-letter-u {
    padding-top: 2vmin;
    width: 4.7vmin;
}

.header-dark {
    position: sticky;
    justify-content: space-between;
    /* padding: 0.4em; */
}
  
.header-dark-title {
    font-size: 5vmin;
    font-family: 'Pretendard';
    font-weight: 500;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
}

.header-light {
    position: sticky;
    background-color: white;
    justify-content: space-between;
    /* padding: 0.4em; */
}
  
.header-light-title {
    font-size: 5vmin;
    font-family: 'Pretendard';
    font-weight: 500;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
}

.section-landing-page {
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-about-page {
    background-color: white;
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-quote-page {
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-mission-page {
    background-color: white;
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-contact-page {
    background-color: white;
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.inflatable-letter-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inflatable-letter {
    width: 10%;
}

.opening-text {
    font-size: 17vmin;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    line-height: 0;
    padding-left: 0.4em;
    padding-right: 0.4em;
    position: absolute;
    bottom: 0;
    color: white;
}

.about-list {
    list-style-type: none;
}

.about-list-items {
    height: 14%;
}

.about-mask {
    display: inline-block;
    overflow: hidden;
}

.about-shuffle {
    display: inline-block;
}

.about-text-indent {
    text-indent: 0.8em;
    font-size: 8vmin;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    line-height: 20%;
    color: black;
}

.about-text-noindent {
    font-size: 8vmin;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;
    line-height: 20%;
    color: black;
}

.quote-text {
    font-size: 7vmin;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: center;
    padding: 0.8em;
    color: white;
}

.blur-text {
    font-size: 7vmin;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: center;
    color: transparent;
    text-shadow: 0px 0px 20px white;
    transition: text-shadow 0.5s linear;
}

.blur-text:hover { 
    text-shadow: 0px 0px 0px #eb3b5d;
}

.mission-text {
    font-size: 7vmin;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: center;
    padding: 0.8em;
    color: black;
}

.contact-small-text {
    font-size: 5vmin;
    font-family: "Pretendard";
    font-weight: 400;
}

.contact-main-text {
    font-size: 9vmin;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    padding: 0.8em;
    color: black;
}

.contact-blur-text {
    font-size: 9vmin;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    color: #eb3b5d;
    transition: 1s linear;
}

.contact-blur-text:hover {
    color: black;
}

.link {
    text-decoration: none;
    color: white;
}

.disabled {
    pointer-events: none;
}

.marquee-light {
    border-top: 1px solid black;  
    border-bottom: 1px solid black;      
    font-size: 4vw;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: left;
    color: black;
    margin: 0;
    padding: 10px;
}

.marquee-dark {
    border-top: 1px solid white;  
    border-bottom: 1px solid white;      
    font-size: 4vw;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: left;
    color: white;
    margin: 0;
    padding: 10px;
}

.mobile-text {
    margin-top: 3vh;
    font-size: 2vw;
    font-family: "Pretendard";
    font-weight: 400;
    color: white;
    text-transform: uppercase;
}