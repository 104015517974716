.about {
    background-color: white;
}

.section-intro-page {
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.section-details-page {
    padding: 64px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bigtext-container {
    width: 100%;
    height: 60vh;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 5%;
}

.smalltext-container {
    width: 100%;
    height: 60vh;
    /* background-color: lightblue; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 5%;
}

.intro-text {
    font-size: 10vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    color: black;
    margin: 0;
}

.intro-blurb {
    font-size: 2vw;
    font-family: "Pretendard";
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    color: black;
}

.about-text {
    font-size: 2vw;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: left;
    color: black;
    margin: 0 60px;
}

.photo {
    max-width: 40%;
    height: auto;
}

.details-container {
    width: 100%;
    height: 20vw;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5%;
}

.details-title {
    font-size: 4vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    color: black;
    margin: 0;
    margin-bottom: 50px;
}

.details-indiv {
    font-size: 2vw;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: left;
    color: black;
    margin: 0;
}

:root {
    --x-speed: 15s;
    --y-speed: 10s;
    --transition-speed: 2.2s;
}

.x1 {
    animation: x1 var(--x-speed) linear infinite alternate;
}

.x2 {
    animation: x2 var(--x-speed) linear infinite alternate;
}
  
.y {
    animation: y var(--y-speed) linear infinite alternate;
}
  
@keyframes x1 {
    100% {
      transform: translateX(calc(50vw));
    }
}

@keyframes x2 {
    100% {
      transform: translateX(calc(-50vw));
    }
}

@keyframes y {
    100% {
        transform: translateY(calc(50vh));
    }
}