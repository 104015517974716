@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);

.app {
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
}


