.contact {
    background-color: black;
}

.container {
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-card {
    width: 380px;
    height: 250px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 40px;
    margin-top: 40px;
}

.contact-text {
    font-size: 30px;
    font-family: "Pretendard";
    font-weight: 600;
    text-transform: uppercase;
    color: white;
}

.contact-subtext {
    font-size: 1.2vw;
    font-family: "Pretendard";
    font-weight: 400;
    color: white;
}

.thankyou-container {
    padding-top: 64px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-title {
    font-size: 7vw;
    font-family: "Pretendard";
    font-weight: 700;
    text-align: center;
    color: white;
    margin: 0;
    align-self: flex-start;
    padding-top: 3%;
    padding-left: 3%;
}

.thank-you {
    font-size: 4vw;
    font-family: "Pretendard";
    font-weight: 500;
    text-align: center;
    color: white;
    margin: 0;
}

.form {
    min-width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form label {
    display: block;
    text-align: left;
    font-size: 1.5vw;
    margin: 0 0 2vh 0;
    font-family: "Pretendard";
    font-weight: 500;
    color: white;
}

.form input, textarea{
    display: block;
    width: 40vw;
    padding: 1vh 1vw 1vh 1vw;
    margin: .9vw 0;
    border: 2px solid white;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    font-size: 1.2vw;
    font-family: "Pretendard";
    font-weight: 400;
    color: white;
    background-color: transparent;
}

.form textarea {
    height: 15vh;
}

.form button {
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: 40%;
    height: 4vh;
    font-size: 1.2vw;
    font-family: "Pretendard";
    font-weight: 600;
    color: black;
    cursor: pointer;
}

.form button:focus {
    background-color: #eb3b5d;
    color: white;
}

.field {
    position: relative;
}